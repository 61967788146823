<div class="helloWorld">
  <h2>Hello World for Angular<img style="height: 25px;" alt="logo"
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==" />
  </h2>
  <input type="text" [value]="resultValue" readonly="true" class="latest-result" placeholder="The Last Read Barcode">
  <div id="UIElement">
    <span style='font-size:x-large' *ngIf="!libLoaded">Loading Library...</span>
    <app-barcode-scanner *ngIf="bShowScanner" (appendMessage)="appendMessage($event)"></app-barcode-scanner>
  </div>
  <div>
    <span style="float:left">All Results:</span><br />
    <div #scrollMe id="results">
      <ul>
        <li *ngFor="let item of resultItems"><span>{{item.type}}</span><span class="resultText">{{item.text}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
